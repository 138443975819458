import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import blitzMessage, {
  EVENTS,
  handleMessage,
  initEvents,
} from "@/__main__/ipc-core.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import LeagueAccountModel from "@/data-models/league-account.mjs";
import lolRefs from "@/game-lol/refs.mjs";
import {
  isDerivedRiotID,
  separateDerivedRiotID,
} from "@/game-lol/utils/derived-id.mjs";
import * as API from "@/game-tft/api.mjs";
import { GAME_SYMBOL_TFT } from "@/game-tft/definition-symbol.mjs";
// import { regionToLanguage } from "@/game-tft/constants.mjs";
import aggregateTftData from "@/game-tft/overlay-aggregate-data.mjs";
import setTftData from "@/game-tft/overlay-set-data.mjs";
import setOverlayInGameSettings from "@/game-tft/overlay-set-settings.mjs";
// import updateTftOverlaySettings from "@/game-tft/overlay-update-settings.mjs";
import { EVENT_OVERLAY_VISIBILITY } from "@/hub-overlays/handle-messages.mjs";
import { devError, devLog, devWarn } from "@/util/dev.mjs";

const KEY_ACTION_KEY_DOWN = "game_hotkey_down";
const KEY_ACTIONS = new Set(["game_hotkey_up", KEY_ACTION_KEY_DOWN]);

interface Obj {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [_: string]: Obj | any;
}

const tftShortName = GAME_SHORT_NAMES[GAME_SYMBOL_TFT];

async function initTFT() {
  await initEvents;

  /**
   * Register a handleMessage listener for when overlay settings
   * are changed in-game.
   */
  handleMessage(EVENTS.UPDATE_TFT_SETTINGS, () => {
    // updateTftOverlaySettings(overlaySettings);
    eventBus.emit(EVENT_OVERLAY_VISIBILITY, {
      game: tftShortName,
      visible: true,
      hasWebOverlay: false,
      trigger: EVENTS.UPDATE_TFT_SETTINGS,
    });
  });

  handleMessage(EVENTS.GET_TFT_OVERLAY_DATA, () => {
    const { lolClient } = lolRefs;
    if (!lolClient) return;
    setTimeout(() => {
      const currentSummoner = readState.volatile?.currentSummoner;
      const region =
        readState.settings?.riotRegion || currentSummoner?.split(":")[0];
      const name = currentSummoner?.split(":")[1];
      const [gameName, tagLine] =
        (isDerivedRiotID(name) && separateDerivedRiotID(name)) || [];
      const language = readState.settings?.selectedLanguage || "en";

      if (!region || !gameName || !tagLine) {
        devError("[TFT] Failed to get region, gameName, or tagLine");
        return;
      }

      getData(
        API.getPlayer(region, gameName, tagLine),
        LeagueAccountModel,
        undefined,
        {
          shouldFetchIfPathExists: true,
          skipSafetyCheck: true,
        },
      ).then((res) => {
        const data = {
          matchInfo: {
            queueId: readState.settings?.lol?.lastQueueId,
            matchId:
              region.toUpperCase() +
              "_" +
              readState.volatile?.currentGameTuple?.[1],
            region: region,
            currentPatch: readState.lol.currentPatch,
          },
          language: language,
          // gameLanguage: getRegionByRiotLanguage(gameLanguage?.[0]),
          subscriber: accountRefs.activeSubscriber,
          // matches: readState.tft.matchlists?.[currentSummoner],
          summonerInfo: {
            name: res?.account.game_name,
            gameName: res?.account.game_name,
            tagLine: res?.account?.tag_line,
            region: region,
            leagues: res?.league_tft,
          },
          blitzVersion: readState.volatile?.appVersion?.value,
        };
        blitzMessage(EVENTS.TFT_OVERLAY_DATA, data);
      });
    }, 1500);
  });

  handleMessage(EVENTS.TFT_DATA, setTftData);

  handleMessage(EVENTS.TFT_ROUND_DATA, aggregateTftData);

  handleMessage(EVENTS.ELECTRON_OVERLAY_HOTKEY_MSG, (event: Obj) => {
    const action = event?.detail?.value?.action;
    if (!KEY_ACTIONS.has(action)) {
      devWarn(
        `[EVENT ELECTRON_OVERLAY_HOTKEY_MSG] INVALID KEY ACTION: ${action}`,
      );
      return;
    }
    const isKeyDown = action === KEY_ACTION_KEY_DOWN;
    devLog(
      `[FROM TFT WEB] overlay.hotkey.test_hotkey is ${
        isKeyDown ? "down" : "up"
      }`,
    );
  });

  handleMessage(EVENTS.APP_OVERLAY_HOTKEYS, (event: Obj) => {
    const value = event?.detail?.value;
    if (!value) return;
    devLog(`[FROM TFT WEB] Binding changed to ${JSON.stringify(value)}`);
  });

  handleMessage(EVENTS.TFT_OVERLAY_SETTINGS, setOverlayInGameSettings);
}

// const getRegionByRiotLanguage = (language) => {
//   return Object.keys(regionToLanguage).find(
//     (key) => regionToLanguage[key] === language,
//   );
// };

export default initTFT;
