import { ref } from "valtio";

import { setVolatileKV } from "@/app/actions.mjs";
import { devDebug } from "@/util/dev.mjs";

export default function overlaySetData(gameDataStr) {
  if (typeof gameDataStr !== "string" || !gameDataStr) {
    devDebug("TFT overlaySetData called with no data!");
    return;
  }
  const gameData = JSON.parse(gameDataStr);
  // We could possibly do model validation here
  setVolatileKV("gameData", ref(gameData));
}
