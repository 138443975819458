import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isPersistent,
} from "@/__main__/app-state.mjs";
import { isVolatile, MAX_TIME } from "@/__main__/constants.mjs";
import { TFT_OVERLAY_SETTINGS } from "@/game-tft/definition.mjs";
import clone from "@/util/clone.mjs";
import deepMerge from "@/util/deep-merge.mjs";

export default function setOverlayInGameSettings(values) {
  const overlaySettings = values;
  let isEmpty = true;
  for (const key in overlaySettings) {
    if (key === isPersistent || key === isVolatile) continue;
    if (typeof overlaySettings[key] !== "undefined") {
      isEmpty = false;
      break;
    }
  }
  const initial = isEmpty ? TFT_OVERLAY_SETTINGS : overlaySettings;
  const overlaysInGame = clone(initial);
  // We are now tracking and adding more nested positioning elements for compositions overlay
  if (!overlaysInGame.compositionsModalOverlay) {
    overlaysInGame.compositionsModalOverlay =
      TFT_OVERLAY_SETTINGS.compositionsModalOverlay;
  }
  deepMerge(overlaysInGame, values);
  // Write to global state
  overlaysInGame[isPersistent] = MAX_TIME;
  writeState.tft.overlaysInGame = overlaysInGame;
}
