import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";

export default function aggregateTftData(roundData) {
  blitzMessage(EVENTS.DB_READ, "TFT_ROUND_DATA").then((stringifiedData) => {
    const allRoundData = JSON.parse(stringifiedData);
    let result = allRoundData ? [...allRoundData] : [];
    if (
      result.length &&
      result[result.length - 1]?.game_id !== roundData.game_id
    )
      result = [];
    result.push(roundData);
    blitzMessage(EVENTS.DB_WRITE, {
      key: "TFT_ROUND_DATA",
      value: JSON.stringify(result),
    });
  });
}
